import React, { useEffect, useMemo } from 'react';
import useI18n from 'i18n/useI18n';
import { CustomImageInput } from 'components/ImageUpload';
import Card from 'components/Card';
import { GridContainer, FormSpacer } from 'containers/FormLayout';
import usePlaces from 'state/places';
import { TitleH3 } from 'components/Texts';
import GROUP_INFO_KEYS from '../../keys';
import { getAvailableCountries } from 'utils/country';
import useCountryOptions from './useCountryOptions';

import { Input } from '@etiquette-ui/inputs-dropdowns';
import Select from './components/Select';
import RequiredContainer from 'components/Inputs/RequiredContainer';

const GroupInformation = ({ formData, handleGroupInfo, errors }) => {
  const { translate, selectedLang } = useI18n();
  const [places, dispatcher] = usePlaces();
  const { countryOptions, setCountry, setState, setCity } = useCountryOptions();

  const defaultCountry = {value: 'MEX', label: 'Mexico'};

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  useEffect(() => {
    const countryData = {
      country: !!formData[GROUP_INFO_KEYS.COUNTRY_ADDRESS] ? formData[GROUP_INFO_KEYS.COUNTRY_ADDRESS] : defaultCountry,
      state: formData[GROUP_INFO_KEYS.STATE_ADDRESS],
      city: formData[GROUP_INFO_KEYS.CITY_ADDRESS]
    }

    setCountry(countryData, places);
  }, []);

  const countries = useMemo(()=>{
    return getAvailableCountries(selectedLang);
  },[selectedLang]);

  return (
    <Card>
      <TitleH3>{translate('Group Information')}</TitleH3>
      <FormSpacer />
      <GridContainer>
        <RequiredContainer>
          <CustomImageInput
            text={translate('Add Picture')}
            altText={translate('Edit Picture')}
            src={formData[GROUP_INFO_KEYS.IMAGE]}
            onChange={(value) => handleGroupInfo(GROUP_INFO_KEYS.IMAGE, value)}
            error={errors[GROUP_INFO_KEYS.IMAGE] && !formData[GROUP_INFO_KEYS.IMAGE] ? translate('File Missing') : undefined}
          />
        </RequiredContainer>        
        <RequiredContainer>
          <Input
            labelProps={{label: translate('Group Name')}}
            iconProps={{
              icon: 'restaurant'
            }}
            inputProps={{
              value: formData[GROUP_INFO_KEYS.NAME],
              onChange: (e) => handleGroupInfo([GROUP_INFO_KEYS.NAME], e.target.value),
              required: true,
            }}
          />
        </RequiredContainer>  
        <RequiredContainer>     
          <Input
            labelProps={{label: translate('Street Address')}}
            iconProps={{
              icon: 'location'
            }}
            inputProps={{
              value: formData[GROUP_INFO_KEYS.STREET_ADDRESS],
              onChange: (e) => handleGroupInfo(GROUP_INFO_KEYS.STREET_ADDRESS, e.target.value),
              required: true,
            }}
          />        
        </RequiredContainer> 
        <GridContainer>
          <RequiredContainer>
            <Input
              labelProps={{label: translate('Number')}}
              iconProps={{
                icon: 'number'
              }}
              inputProps={{
                value: formData[GROUP_INFO_KEYS.STREET_NUMBER],
                onChange: (e) => handleGroupInfo(GROUP_INFO_KEYS.STREET_NUMBER, e.target.value),
                required: true,
              }}
            />
          </RequiredContainer>
          <RequiredContainer>
            <Input
              labelProps={{label: translate('ZIP Code')}}
              iconProps={{
                icon: 'zip-code'
              }}
              inputProps={{
                value: formData[GROUP_INFO_KEYS.ZIP_CODE],
                onChange: (e) => handleGroupInfo(GROUP_INFO_KEYS.ZIP_CODE, e.target.value),
                required: true,
                name: 'zipCode',
                type: 'number',
              }}
            />
          </RequiredContainer>
        </GridContainer>
        <RequiredContainer>
          <Select
            labelProps={{
              label: translate('Country')
            }} 
            leadingIconProps={{
              icon: 'estate',
              leadingIconStyles: {
                svg:{
                  width: '27px',
                  height: 'auto',
                }
              }
            }} 
            selectProps={{
              options: countries,
              value: formData[GROUP_INFO_KEYS.COUNTRY_ADDRESS],
              defaultValue: defaultCountry,
              onChange: (value) => {
                setCountry({
                  country: value,
                  state: formData[GROUP_INFO_KEYS.STATE_ADDRESS],
                  city: formData[GROUP_INFO_KEYS.CITY_ADDRESS]
                }, places);
                setCountry(value, places);
                handleGroupInfo(GROUP_INFO_KEYS.COUNTRY_ADDRESS, value);
              },
              required: true,
              placeholder: translate('Choose one')
            }}
            errorProps={{error: errors[GROUP_INFO_KEYS.COUNTRY_ADDRESS] ? translate('Required Field') : ''}}
          />
        </RequiredContainer>
        <RequiredContainer>
          <Select
            labelProps={{
              label: translate('State')
            }} 
            leadingIconProps={{
              icon: 'estate',
              leadingIconStyles: {
                svg:{
                  width: '27px',
                  height: 'auto',
                }
              }
            }} 
            selectProps={{
              options: countryOptions.states.map(({ id, name }) => ({ value: id, label: name })),
              value: formData[GROUP_INFO_KEYS.STATE_ADDRESS],
              onChange: (value) => {
                setState(value);
                handleGroupInfo(GROUP_INFO_KEYS.STATE_ADDRESS, value)
              },
              required: true,
              placeholder: translate('Choose one'),
              isSearchable: true
            }}
            errorProps={{error: errors[GROUP_INFO_KEYS.STATE_ADDRESS] ? translate('Required Field') : ''}}
          />          
        </RequiredContainer>
        <RequiredContainer>
          <Select
            labelProps={{
              label: translate('City')
            }} 
            leadingIconProps={{
              icon: 'estate',
              leadingIconStyles: {
                svg:{
                  width: '27px',
                  height: 'auto',
                }
              }
            }} 
            selectProps={{
              options: countryOptions.stateCities.map(({ id, name }) => ({ value: id, label: name })),
              value: formData[GROUP_INFO_KEYS.CITY_ADDRESS],
              onChange: (value) => {
                setCity(value);
                handleGroupInfo(GROUP_INFO_KEYS.CITY_ADDRESS, value);
              },
              required: true,
              placeholder: translate('Choose one'),
              isSearchable: true
            }}
            errorProps={{error: errors[GROUP_INFO_KEYS.CITY_ADDRESS] ? translate('Required Field') : ''}}
          />
        </RequiredContainer>
        <RequiredContainer>
          <Select
            labelProps={{
              label: translate('Timezone')
            }} 
            leadingIconProps={{
              icon: 'website',
              leadingIconStyles: {
                svg:{
                  width: '25px',
                  height: 'auto',
                },
                line:{
                  fill: 'none',
                  strokeLinecap:'round',
                  strokeLinejoin:'round',
                  strokeWidth:'1px',
                  stroke: 'black'
                }
              }
            }} 
            selectProps={{
              options: countryOptions.timezones,
              value: formData[GROUP_INFO_KEYS.TIME_ZONE],
              onChange: (value) => handleGroupInfo(GROUP_INFO_KEYS.TIME_ZONE, value),
              required: true,
              placeholder: translate('Choose one'),
              isSearchable: true,
            }}
            errorProps={{error: errors[GROUP_INFO_KEYS.TIME_ZONE] ? translate('Required Field') : ''}}
          />
        </RequiredContainer>
      </GridContainer>
    </Card>
  );
};
export default GroupInformation;