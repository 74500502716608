import GROUP_INFO_KEYS from './keys';

export const DEFAULT_COUNTRY_CODE = 'MX';

export const INIT_CONTACT = {
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactCountryCode: DEFAULT_COUNTRY_CODE,
  contactPhone: '',
  contactPosition: '',
};

export const DEFAULT_INIT_TIME = {
  hour: '',
  minute: '',
  time: '',
};

export const DEFAULT_SCHEDULE = {
  isOpen: false,
  hours: [{...DEFAULT_INIT_TIME}, {...DEFAULT_INIT_TIME}, {...DEFAULT_INIT_TIME}],
};

export const DEFAULT_SPECIAL_DATE = {
  date: null,
  isOpen: false,
  hours: [{...DEFAULT_INIT_TIME}, {...DEFAULT_INIT_TIME}, {...DEFAULT_INIT_TIME}],
};

export const DEFAULT_CONFIG = {
  openingHours: {
    schedule: {
      monday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
      tuesday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
      wednesday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
      thursday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
      friday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
      saturday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
      sunday: { ...DEFAULT_SCHEDULE, hours: [...DEFAULT_SCHEDULE.hours] },
    },
    specialDates: [{...DEFAULT_SPECIAL_DATE, hours: [...DEFAULT_SPECIAL_DATE.hours]}],
  },
};

const INIT_STATE = {
  [GROUP_INFO_KEYS.IMAGE]: '',
  [GROUP_INFO_KEYS.NAME]: '',
  [GROUP_INFO_KEYS.STREET_ADDRESS]: '',
  [GROUP_INFO_KEYS.STREET_NUMBER]: '',
  [GROUP_INFO_KEYS.ZIP_CODE]: '',
  [GROUP_INFO_KEYS.COUNTRY_ADDRESS]: null,
  [GROUP_INFO_KEYS.STATE_ADDRESS]: null,
  [GROUP_INFO_KEYS.CITY_ADDRESS]: null,
  [GROUP_INFO_KEYS.TIME_ZONE]: null,
  contacts: [{ ...INIT_CONTACT }],
  config: {
    ...DEFAULT_CONFIG,
  },
};

export default INIT_STATE;
