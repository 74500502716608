import React from 'react';
import { Card } from '@etiquette-ui/misc';
import useI18n from 'i18n/useI18n';
import { TitleH3 } from 'components/Texts';
import { FormSpacer, CustomContainer } from 'containers/FormLayout';
import PERSONAL_INFO_KEYS from 'components/EmployeeForm/keys';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import { RadioButton } from '@etiquette-ui/selectors';
import styled from 'styled-components';
import RequiredContainer from '../../Inputs/RequiredContainer';

const CustomCard = styled(Card)`
  padding-bottom: 20px;
`;

const AccountAccess = ({ formData, onChangeForm, errors, isEdit = false }) => {
  const { translate } = useI18n();
  return (
    <CustomCard>
      <TitleH3>{translate('Account Access')}</TitleH3>
      <FormSpacer />
      <CustomContainer columns="3" width="80%">
        <RadioButton
          checked={formData[PERSONAL_INFO_KEYS.USER_TYPE] === 'regular'}
          onClick={() => onChangeForm(PERSONAL_INFO_KEYS.USER_TYPE, 'regular')}
        >{translate('Regular user')}</RadioButton>
        <RadioButton
          checked={formData[PERSONAL_INFO_KEYS.USER_TYPE] === 'global'}
          onClick={() => onChangeForm(PERSONAL_INFO_KEYS.USER_TYPE, 'global')}
        >{translate('Global user')}</RadioButton>
        <RadioButton
          checked={formData[PERSONAL_INFO_KEYS.USER_TYPE] === 'root'}
          onClick={() => onChangeForm(PERSONAL_INFO_KEYS.USER_TYPE, 'root')}
        >{translate('Root user')}</RadioButton>
      </CustomContainer>
      <FormSpacer />
      <CustomContainer columns="2">
        <RequiredContainer>
          <Input
            labelProps={{label: translate('Email')}}
            iconProps={{
              icon: 'user'
            }}
            inputProps={{
              value: formData[PERSONAL_INFO_KEYS.EMAIL],
              onChange: (e) => onChangeForm(PERSONAL_INFO_KEYS.EMAIL, e.target.value),
              type: 'email',
            }}
            errorProps={{error: !!errors[PERSONAL_INFO_KEYS.EMAIL] ? errors[PERSONAL_INFO_KEYS.EMAIL] : ''}}
          />
        </RequiredContainer>
        <RequiredContainer>
          <Input
            labelProps={{label: translate('Password')}}
            inputProps={{
              type: 'password',
              value: formData[PERSONAL_INFO_KEYS.PASS],
              onChange: (e) => onChangeForm(PERSONAL_INFO_KEYS.PASS, e.target.value),
              required: isEdit ? false : true
            }}  
            errorProps={{ error: !!errors[PERSONAL_INFO_KEYS.PASS] ? errors[PERSONAL_INFO_KEYS.PASS] : ''}}  
            autoComplete="new-password"        
          />
        </RequiredContainer>        
      </CustomContainer>
    </CustomCard>
  );
};

export default AccountAccess;
