import { countries } from 'countries-list';
import { availableCountries } from 'resources/constants/countries';

const formattedCountries = Object.keys(countries).map((code) => ({
    label: countries[code].name,
    value: code,
    ...countries[code],
}));

export const findCountryPhoneCode = (phoneCode) => {
    return findCountry(phoneCode)?.phone;
}

export const findCountry = (countryValue) => {
    return formattedCountries.find((country)=>countryValue===country.value);
}

export const getAvailableCountries = (lang) => {
    const index = lang === 1 ? 'en' : 'es';
    
    return availableCountries.map( country => ({
        value: country.value,
        label: country.label[index]
    }))
}
