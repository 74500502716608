const EN = {
    "Search": "Search",
    "Consolidate": "Consolidate",
    "Add Group": "Add Group",
    "All Groups": "All Groups",
    "Back": "Back",
    "Back to Home": "Back to Home",
    "Back to Permissions": "Back to Permissions",
    "Edit Profile": "Edit Profile",
    "Delete": "Delete",
    "Cancel": "Cancel",
    "Save": "Save",
    "Update": "Update",
    'Required': 'Required',

    "Change Group":"Change Group",
    "Change":"Change",

    "Group Name": "Group Name",
    "# Employees": "# Employees",
    "Apps": "Apps",
    "New Group": "New Group",
    "Active Groups": "Active Groups (%count%)",
    "Inactive Groups": "Inactive Groups (%count%)",

    "Group Information": "Group Information",
    "Street Address": "Street Address",
    "Number": "Number",
    "ZIP Code": "ZIP Code",
    "State": "State",
    "City": "City",
    "Main Contact": "Main Contact",
    "Schedule": "Schedule",
    "Country":"Country",
    "Timezone":"Timezone",
    
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Name": "Name",
    "Email Address": "Email Address",
    "Job Position": "Job Position",
    "Mobile Phone": "Mobile Phone",
    "Add Picture": "Add Picture",
    "Edit Picture": "Edit Picture",
    "Create New Group": "Create New Group",
    "Delete Group": "Delete Group",
    "Are you sure you want to delete this group?": "Are you sure you want to delete this group?",

    "Groups": "Groups",
    "Group": "Group",
    "Permissions": "Permissions",
    "Divisions": "Divisions",
    "Roles": "Roles",
    "Employees": "Employees",
    "Management": "Management",
    "Email Templates": "Email Templates",
    "Sales Adjustment": "Sales Adjustment",
    "Profile": "Profile",

    "Address": "Address",

    "Add Employee" : "Add employee",

    "Add Groups" : "Add Groups",

    "New Permission": "New Permission",
    "Details": "Details",
    "Permission Name (without spaces)": "Permission Name (without spaces)",
    "Description": "Description",
    "Applications": "Applications",
    "Add Applications": "Add Applications",
    "Adding Applications": "Adding Applications",
    "Press button to add applications.": "Press button to add applications.",
    "Delete Permission": "Delete Permission",
    "Are you sure you want to delete this permission?": "Are you sure you want to delete this permission?",

    "Add Permission" : "Add Permission",

    "Add Division" : "Add Division",
    "Delete Division" : "Delete Division",
    "Are you sure you want to delete this division?": "Are you sure you want to delete this division?",
    "Head": "Head",

    "Available Apps":"Available Apps",
    "Learn More":"Learn More",
    "Need Applications":"Do you need more of our applications? Contact us to help you",
    
    "Back Profile":"Back to Profile",
    "Learn Apps":"Learn More About Our Apps",
    "Request Apps":"Request Applications ",
    "Should Select":"You should select at least one app to send request",
    "Already":"You already have it",
    "I Want":"I want this",

    "Thanks":"Thank you!",
    "Request Received":"Your request has been received.",
    "Our Customer":"Our Customer Support will contact the Main Contact of Restaurant to enable the applications requested by you.",
    "Your Request":"Your request",

    "Add employee" : "Add employee",
    "Delete Employee": "Delete Employee",
    "Are you sure you want to delete this employee?": "Are you sure you want to delete this employee?",

    "User Name":"Username",
    "Email":"Email",
    "Password":"Password",
    "Second Name":"Second Name",
    "User Id":"User ID",
    "Pos Id":"POS ID",
    "POS ID":"POS ID",
    "ID Information":"ID Information",
    "Permission Name":"Permission Name",
    "Assign Division":"Assign Division",
    "Assign Role":"Assign Role",
    "Create Employee":"Create Employee",
    "Update Employee" : "Update Employee",
    "Invalid Phone Number" : "Invalid Phone Number",
    "Invalid Email" : "Invalid Email",

    "Import Data":"Import Data",
    "Back to Employees":"Back to Employees",
    "New Employee":"New Employee",

    "Personal Information":"Personal Information",
    "Account Access":"Account Access",
    "Id Information":"ID information",
    "Assign Permission":"Assign Permission",
    "Division and Role":"Division and Role",

    "Edit Employee":"Edit Employee",
    "Restaurant":"Restaurant",
    "Restaurant to Assign":"Restaurant to Assign",
    "Role":"Role",
    "Add Role":"Add Role",
    "Back to Roles":"Back to Roles",
    "Edit Role":"Edit Role",
    "Update Role":"Update Role",

    "New Role":"New Role",
    "Create Role":"Create Role",
    "Role Name":"Role Name",
    "Delete Role" : "Delete Role",
    "Are you sure you want to delete this role?": "Are you sure you want to delete this role?",

    "Division":"Division",
    "Back to Divisions":"Back to Divisions",
    "New Division":"New Division",
    "Create Division":"Create Division",
    "Permission":"Permission",
    "Add Restaurant":"Add Restaurant",
    "Home":"Home",

    "Add Employees":"Add Employees",
    "Description of Division":"Description of Division",
    "Head(s) of the Division (max 3)":"Head(s) of the Division (max 3)",
    "Head(s) of the Division":"Head(s) of the Division",
    "Create Permission":"Create Permission",
    "Edit Group":"Edit Group",
    "Secondary Contact":"Secondary Contact",
    "Add Secondary Contact":"Add Secondary Contact",
    "Remove Contact":"Remove Contact",
    "Remove Assignment":"Remove Assignment",
    "Regular user":"Regular user",
    "Global user":"Global user",
    "Root user":"Root user",
    "You are in": "You are in",
    
    "Add Selected Applications":"Add Selected Applications",
    "Go Back": "Go Back",
    'Activate': 'Activate',
    'Desactivate':'Desactivate',
    'Activate Restaurant': 'Activate Restaurant',
    'Inactive Restaurant':'Inactivate Restaurant',
    'Want Activate':'Do you want activate this restaurant?',
    'Apps available':'All apps available for this restaurant will be activated and will start working again.',
    'Want inactivate':'Do you want inactivate this restaurant?',
    'Apps desactivated': 'All apps available for this restaurant will be desactivated, so they will stop working until restaurant is activated again',
    "View Permission Details": "View Permission Details",

    "Modification Date": "Modification Date",
    "Tags":"Tags",
    "Tag":"Tag",
    "Add Template":"Add Template",
    "Template Name":"Template Name",
    "New Email Template":"New Email Template",
    "Languages":'Languages',
    "Subject":"Subject",
    "Subject in":"Subject in %lang%",
    "Message in":"Message in %lang%",
    "Add comments":"Add comments",
    "Message":"Message",
    "Create Email Template":"Create Email Template",
    'Positive Tag':'Positive Tag',
    'Negative Tag':'Negative Tag',
    'Neutral Tag':'Neutral Tag',
    'positive':'Positive',
    'negative':'Negative',
    'neutral':'Neutral',
    "Back to Email Template": "Back to Email Template",
    "Which languages ​​will be available": "Which languages ​​will be available",
    "At least one translation required": "At least one translation required",

    "Event Date":"Event Date",
    "Name of Sale":"Name of Sale",
    "Amount":"Amount",
    "Type":"Type",
    "Payment":"Payment",
    "Last Entry":"Last Entry",
    "Add Adjustment":"Add Adjustment",
    "Back to Sales Adjustment":"Back to Sales Adjustment",
    "New Adjustment":"New Adjustment",
    "Payments":"Payments",
    "Payment Method":"Payment Method",
    "Food Sale":"Food Sale",
    "Drink Sale": "Drink Sale",
    "Add New Payment":"Add New Payment",
    "Save Adjustment":"Save Adjustment",
    'Adjustment':'Adjustment',
    'Event':'Event',
    'Add Iva':'Add IVA',
    'Includes Iva':'Includes IVA',
    "Event Amount":"Event Amount",
    "Press button": 'Press button to add another payment to this event/sale',
    'Adjustment Date':'Adjustment Date',
    "Food/Drink/IVA":"Food/Drink/IVA",
    "Credit Card":"Credit Card",
    "Total Payment":"Total Payment",
    "Payment History":"Payment History",
    "Sales adjustment name":"Sales adjustment name",
    "Delete Sale Adjustment":"Delete Sale Adjustment",
    "Are you sure you want to delete this sale adjustment?":"Are you sure you want to delete this sale adjustment?",

    "Edit Email Template":"Edit Email Template",
    "Delete Email Template" : "Delete Email Template",
    "Are you sure you want to delete this template?": "Are you sure you want to delete this template?",

    "At least one permission required":"At least one permission required",
    "Errors found on Form":"Errors found on Form",
    "File Missing":"File Missing",
    "You must assign at least one group":"You must assign at least one group",

    "Update Division":"Update Division",
    "Edit Division":"Edit Division",
    "Edit Permission" :"Edit Permission",

    'Division/Role':"Division/Role",
    'No Division':"No Division",
    "Short Access":"Short Access",
    "Nick Name":"Nick Name",
    "Short Password" : "Short Password",
    "Managers Permission Details":"Managers Permission Details",

    "Update Permission":"Update Permission",

    "Which languages will be available":"Which languages will be available",
    "English":"English",
    "Spanish": "Spanish",

    "Back to groups":"Back to Groups",

    "Internal Server Error": "Internal Server Error",
    "Please report this to the dev team.": "Please report this to the dev team.",
    "OK": "Ok",
    "Maximum file size is:": "Maximum file size is: %size%",

    "Opening Hours": "Opening Hours",
    "open": "Open",
    "closed": "Closed",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "Opening": "Opening",
    "Mid Start": "Mid Start",
    "Mid": "Mid",
    "Close": "Close",
    "Holidays & Special events": "Holidays & Special events",
    "Date": "Date",
    "Add Special Date": "Add Special Date",
    "Schedule for today not defined yet": "Schedule for today not defined yet",
    "Closed": "Closed",

    "Unauthorized": "Unauthorized",
    "You don't have access to this app": "You don't have access to this app",

    "Survey Builder": "Survey Builder",
    "Your Surveys": "Your Surveys",
    "Creation": "Creation",
    "Modification": "Modification",
    "Action Items": "Action Items",

    'A permission type is required.': 'A permission type is required.',
    'One Division and its Role are required at least. Verify each Division has a Role assigned.': 'One Division and its Role are required at least. Verify each Division has a Role assigned.',

    'Each group requires a permission type. Verify the info and try again.': 'Each group requires a permission type. Verify the info and try again.',
    'Each group requires a Division with its Role at least. Verify each Division has a Role assigned.': 'Each group requires a Division with its role at least. Verify each Division has a Role assigned.',

    'Choose one': 'Choose one',
    'Choose a Division first': 'Choose a Division first',
    'Select an Employee and one role for Division.': 'Select an Employee and one role for Division.',
    'Choose an employee': 'Choose an employee',
    'Choose an employee first.': 'Choose an employee first.',
    'No Role options for the chosen Employee': 'No Role options for the chosen Employee',
    'No Division options for the chosen Employee': 'No Division options for the chosen Employee',

    'Name is required': 'Name is required',
    'Last Name is required': 'Last Name is required',
    'Email is required': 'Email is required',
    'Password is required': 'Password is required',
    'All Countries': 'All Countries',
}   

export default EN;