const ES = {
    "Search": "Buscar",
    "Consolidate": "Consolidar",
    "Add Group": "Añadir Grupo",
    "All Groups": "Todos",
    "Back": "Regresar",
    "Back to Home": "Regresar al Inicio",
    "Back to Permissions": "Regresar a Permisos",
    "Edit Profile": "Editar Perfil",
    "Delete": "Eliminar",
    "Cancel": "Cancelar",
    "Save": "Guardar",
    "Update": "Actualizar",
    'Required': 'Requerido',

    "Change Group":"Change Group",
    "Change":"Ir",

    "Group Name": "Nombre del grupo",
    "# Employees": "# Empleados",
    "Apps": "Apps",
    "New Group": "Nuevo Grupo",
    "Active Groups": "Grupos Activos (%count%)",
    "Inactive Groups": "Grupos Inactivos (%count%)",

    "Group Information": "Información del Grupo",
    "Street Address": "Dirección (Calle)",
    "Number": "Número",
    "ZIP Code": "Código Postal",
    "State": "Estado",
    "City": "Ciudad",
    "Main Contact": "Contacto Principal",
    "Schedule": "Horario",
    "Country":"País",
    "Timezone":"Zona horaria",

    "First Name": "Nombre",
    "Last Name": "Apellido",
    "Name": "Nombre",
    "Email Address": "Correo electrónico",
    "Job Position": "Posición en la empresa",
    "Mobile Phone": "Teléfono celular",
    "Add Picture": "Añadir Foto",
    "Edit Picture": "Editar Foto",
    "Create New Group": "Crear Nuevo Grupo",
    "Delete Group": "Eliminar Grupo",
    "Are you sure you want to delete this group?": "¿Estás seguro de querer eliminar este grupo?",

    "Groups": "Grupos",
    "Group": "Grupo",
    "Permissions": "Permisos",
    "Divisions": "Divisiones",
    "Roles": "Roles",
    "Employees": "Empleados",
    "Management": "Administración",
    "Email Templates": "Plantillas para emails",
    "Sales Adjustment": "Ajuste de ventas",
    "Profile": "Perfil",

    "Address": "Dirección",

    "Add Employee" : "Nuevo empleado",

    "Add Groups" : "Añadir Grupos",

    "New Permission": "Nuevo Permiso",
    "Details": "Detalles",
    "Permission Name (without spaces)": "Nombre de Permiso (sin espacios)",
    "Description": "Descripción",
    "Applications": "Aplicaciones",
    "Add Applications": "Añadir Aplicaciones",
    "Adding Applications": "Añadiendo Aplicaciones",
    "Press button to add applications.": "Presiona el botón para añadir aplicaciones.",
    "Delete Permission": "Eliminar Permiso",
    "Are you sure you want to delete this permission?": "¿Estás seguro de querer eliminar este permiso?",

    "Add Permission" : "Añadir Permiso",

    "Add Division" : "Añadir División",
    "Delete Division": "Eliminar División",
    "Are you sure you want to delete this division?": "¿Estás seguro de querer eliminar esta división?",
    "Head": "Head",

    "Available Apps":"Aplicaciones disponibles",
    "Learn More":"Aprende más",
    "Need Applications":"¿Necesitas más de nuestras aplicaciones? Contáctanos para ayudarte",

    "Back Profile":"Volver al perfil",
    "Learn Apps":"Más información sobre nuestras aplicaciones",
    "Request Apps":"Solicitar aplicaciones",
    "Should Select":"Debe seleccionar al menos una aplicación para enviar la solicitud",
    "Already":"Ya lo tiene",
    "I Want":"Quiero esto",

    "Thanks":"Gracias!",
    "Request Received":"Su petición ha sido enviada.",
    "Our Customer":"Nuestro servicio de atención al cliente se pondrá en contacto con el contacto principal del restaurante para habilitar las aplicaciones solicitadas por usted.",
    "Your Request":"Tu petición",

    "Add employee" : "Nuevo empleado",
    "Delete Employee": "Eliminar Empleado",
    "Are you sure you want to delete this employee?": "¿Estás seguro de querer eliminar este empleado?",

    "User Name":"Nombre de usuario",
    "Email":"Correo",
    "Password":"Contraseña",
    "Second Name":"Segundo Nombre",
    "User Id":"ID de usuario",
    "Pos Id":"ID POS",
    "POS ID":"ID en POS",
    "ID Information":"Información de ID",
    "Permission Name":"Nombre de permiso",
    "Assign Division":"Asignar división",
    "Assign Role":"Asignar rol",
    "Create Employee":"Crear empleado",
    "Update Employee" : "Modificar empleado",
    "Invalid Phone Number" : "Número de teléfono invalido",
    "Invalid Email" : "E-mail invalido",

    "Import Data":"Importar datos",
    "Back to Employees":"Regresar a empleados",
    "New Employee":"Nuevo empleado",
    
    "Personal Information":"Información Personal",
    "Account Access":"Acceso a la cuenta",
    "Id Information":"Información de ID",
    "Assign Permission":"Asignar permiso",
    "Division and Role":"División y rol",

    "Edit Employee":"Editar Empleado",
    "Restaurant":"Restaurante",
    "Restaurant to Assign":"Restaurante para asignar",
    "Role":"Rol",
    "Add Role":"Agregar rol",
    "Back to Roles":"Regresar a roles",
    "Edit Role":"Editar roles",
    "Update Role":"Actualizar rol",

    "New Role":"Nuevo Rol",
    "Create Role":"Crear Rol",
    "Role Name":"Nombre de Rol",
    "Delete Role" : "Eliminar Rol",
    "Are you sure you want to delete this role?": "¿Estás seguro de querer eliminar este rol?",

    "Division":"División",
    "Back to Divisions":"Regresar a Divisiones",
    "New Division":"Nueva División",
    "Create Division":"Crear División",
    "Permission":"Permiso",
    "Add Restaurant":"Agregar Restaurante",
    "Home":"Inicio",

    "Add Employees":"Agregar Empleados",
    "Description of Division":"Descripción de la División",
    "Head(s) of the Division (max 3)":"Jefes de División (max 3)",
    "Head(s) of the Division":"Jefes de División",
    "Create Permission":"Crear Permiso",
    "Edit Group":"Editar Grupo",
    "Secondary Contact":"Contacto Secundario",
    "Add Secondary Contact":"Agregar Contacto Secundario",
    "Remove Contact":"Eliminar Contacto",
    "Remove Assignment":"Eliminar Asignación",
    "Regular user":"Usuario Regular",
    "Global user":"Usuario Global",
    "Root user":"Usuario Root",
    "You are in": "Información de",

    "Add Selected Applications":"Agregar Aplicaciones Selecionadas",
    "Go Back": "Regresar",
    'Activate':'Activar',
    'Desactivate': 'Desactivar',
    'Activate Restaurant':'Activar Restaurante',
    'Inactive Restaurant':'Desactivar Restaurante',
    'Want Activate':'¿Quieres activar este restaurante?',
    'Apps available':'Todas las aplicaciones disponibles para este restaurante se activarán y comenzarán a funcionar nuevamente.',
    'Want inactivate':'Do you want inactivate this restaurant?',
    'Apps desactivated': 'Todas las aplicaciones disponibles para este restaurante se desactivarán, por lo que dejarán de funcionar hasta que el restaurante se active de nuevo.',
    "View Permission Details": "Ver detalles de permisos",

    "Modification Date": "Fecha de modificación",
    "Tags":"Etiquetas",
    "Tag":"Etiqueta",
    "Add Template":"Agregar Plantilla",
    "Template Name":"Nombre de la plantilla",
    "New Email Template":"Nueva plantilla de correo electrónico",
    "Languages":'Idiomas',
    "Subject":"Asunto",
    "Subject in":"Asunto en %lang%",
    "Message in":"Mensaje en %lang%",
    "Add comments":"Agregar comentarios",
    "Message":"Mensaje",
    "Create Email Template":"Crear plantilla de correo electrónico",
    'Positive Tag': 'Etiqueta Positiva',
    'Negative Tag': 'Etiqueta Negativa',
    'Neutral Tag': 'Etiqueta Neutral',
    'positive': 'Positiva',
    'negative': 'Negativa',
    'neutral': 'Neutral',
    "Back to Email Template": "Volver a la plantilla de correo electrónico",
    "Which languages ​​will be available": "Que lenguajes estarán disponibles",
    "At least one translation required": "Se requiere al menos una traducción",

    "Event Date":"Fecha del evento",
    "Name of Sale":"Nombre de la venta",
    "Amount":"Cantidad",
    "Type":"Tipo",
    "Payment":"Pago",
    "Last Entry":"Última entrada",
    "Add Adjustment":"Agregar Ajuste",
    "Back to Sales Adjustment":"Volver a Ajuste de Ventas",
    "New Adjustment" : "Nuevo Ajuste",
    "Payments":"Pagos",
    "Payment Method":"Método de pago",
    "Food Sale":"Venta de comida",
    "Drink Sale": "Venta de bebida",
    "Add New Payment":"Agregar nuevo pago",
    "Save Adjustment":"Guardar Ajuste",
    'Adjustment':'Ajustes',
    'Event':'Evento',
    'Add Iva':'Agregar IVA',
    'Includes Iva':'Incluir IVA',
    "Event Amount":"Monto del evento",
    "Press button": 'Presione el botón para agregar otro pago a este evento / venta',
    'Adjustment Date':'Ajustes de fecha',
    "Food/Drink/IVA":"Comida/Bebida/IVA",
    "Credit Card": "Tarjeta de crédito",
    "Total Payment":"Pago Total",
    "Payment History":"Historial de pago",
    "Sales adjustment name":"Nombre del ajuste de venta",
    "Delete Sale Adjustment":"Eliminar Ajuste de Venta",
    "Are you sure you want to delete this sale adjustment?":"¿Estás seguro de querer eliminar este ajuste de venta?",

    "Edit Email Template":"Editar plantilla de correo electrónico",
    "Delete Email Template" : "Eliminar Plantilla",
    "Are you sure you want to delete this template?": "¿Estás seguro de querer eliminar esta plantilla?",

    "At least one permission required":"Se requiere al menos un permiso",
    "Errors found on Form":"Errores encontrados en el formulario",
    "File Missing":"Archivo faltante",
    "You must assign at least one group":"Tienes que asignar al menos un grupo",
    
    "Update Division":"Actualizar División",
    "Edit Division":"Editar División",
    "Edit Permission" :"Editar Permiso",

    'Division/Role':"División / Rol",
    'No Division':"Sin División",
    "Short Access":"Acceso rápido",
    "Nick Name":"Apodo",
    "Short Password" : "Contraseña corta",
    "Managers Permission Details":"Detalles del permiso de los gerentes",

    "Update Permission":"Actualizar Permiso",

    "Which languages will be available": "Qué idiomas estarán disponibles",
    "English":"Inglés",
    "Spanish":"Español",

    "Back to groups": "Regresar a Grupos",

    "Internal Server Error": "Error Interno del Servidor",
    "Please report this to the dev team.": "Favor de reportar esto al equipo de desarrollo.",
    "OK": "Ok",
    "Maximum file size is:": "El tamaño máximo de archivo son: %size%",

    "Opening Hours": "Horas de Apertura",
    "open": "Abierto",
    "closed": "Cerrado",
    "monday": "Lunes",
    "tuesday": "Martes",
    "wednesday": "Miércoles",
    "thursday": "Jueves",
    "friday": "Viernes",
    "saturday": "Sábado",
    "sunday": "Domingo",
    "Opening": "Apertura",
    "Mid Start": "Inicio Intermedio",
    "Mid": "Intermedio",
    "Close": "Cierre",
    "Holidays & Special events": "Días Festivos & Eventos Especiales",
    "Date": "Fecha",
    "Add Special Date": "Añadir Fecha Especial",
    "Schedule for today not defined yet": "Horario para hoy aún no está definido",
    "Closed": "Cerrado",

    "Unauthorized": "No autorizado",
    "You don't have access to this app": "No tienes acceso a esta aplicación",

    "Survey Builder": "Constructor de Encuestas",
    "Your Surveys": "Tus Encuestas",
    "Creation": "Creación",
    "Modification": "Modificación",
    "Action Items": "Acciones",

    'A permission type is required.': 'Es necesario asignar un tipo de permiso.',
    'One Division and its Role are required at least. Verify each Division has a Role assigned.': 'Se requiere, al menos, asignar una División y un Rol. Verifíca que cada División tenga un Rol asignado.',

    'Each group requires a permission type. Verify the info and try again.': 'Cada grupo debe tener un tipo de permiso asignado. Revisa la información e intenta de nuevo.',
    'Each group requires a Division with its Role at least. Verify each Division has a Role assigned.': 'Cada grupo requiere, al menos, una División y su Rol. Verifica que cada División tiene un Rol asignado.',
    
    'Choose one': 'Elige uno',
    'Choose a Division first': 'Elige una División primero',
    'Select an Employee and one role for Division.': 'Elige un Empleado y su Rol para esta División.',
    'Choose an employee': 'Selecciona un Empleado',    
    'Choose an employee first.': 'Elige un Empleado de la lista.',
    'No Role options for the chosen Employee': 'No hay Roles disponibles para el Empleado elegido.',
    'No Division options for the chosen Employee': 'No hay Divisiones disponibles para el Empleado elegido.',

    'Name is required': 'Nombre es obligatorio',
    'Last Name is required': 'Apellido es obligatorio',
    'Email is required': 'Email es obligatorio',
    'Password is required': 'Password is required',
    'All Countries': 'Todos',
}

export default ES;