import React from 'react';
import { Card } from '@etiquette-ui/misc';
import useI18n from 'i18n/useI18n';
import { TitleH3 } from 'components/Texts';
import { FormSpacer, CustomContainer } from 'containers/FormLayout';
import { CustomImageInput, IMAGE_SHAPES } from 'components/ImageUpload';
import PERSONAL_INFO_KEYS from '../keys';
import styled from 'styled-components';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import RequiredContainer from '../../Inputs/RequiredContainer';

const CustomCard = styled(Card)`
  padding-bottom: 20px;
`;

const Personal = ({ formData, onChangeForm, errors }) => {
  const { translate } = useI18n();
  return (
    <CustomCard>
      <TitleH3>{translate('Details')}</TitleH3>
      <FormSpacer />
      <CustomImageInput
        text={translate('Add Picture')}
        altText={translate('Edit Picture')}
        src={formData?.image}
        onChange={(value) => onChangeForm('image', value)}
        shape={IMAGE_SHAPES.CIRCLE}
        error={errors.image && !formData.image ? translate('File Missing') : undefined}
      />
      <FormSpacer />
      <CustomContainer columns="3">
        <RequiredContainer>
          <Input
            labelProps={{label: translate('Name')}}
            iconProps={{
              icon: 'user'
            }}
            inputProps={{
              value: formData[PERSONAL_INFO_KEYS.NAME],
              onChange: (e) => onChangeForm(PERSONAL_INFO_KEYS.NAME, e.target.value),
            }}
            errorProps={{error: !!errors[PERSONAL_INFO_KEYS.NAME] ? errors[PERSONAL_INFO_KEYS.NAME] : ''}}
          />
        </RequiredContainer>        
        <Input
          labelProps={{label: translate('Second Name')}}
          iconProps={{
            icon: 'user'
          }}
          inputProps={{
            value: formData[PERSONAL_INFO_KEYS.SECOND_NAME],
            onChange: (e) => onChangeForm(PERSONAL_INFO_KEYS.SECOND_NAME, e.target.value),
          }}
        />
        <RequiredContainer>
          <Input
            labelProps={{label: translate('Last Name')}}
            iconProps={{
              icon: 'user'
            }}
            inputProps={{
              value: formData[PERSONAL_INFO_KEYS.LAST_NAME],
              onChange: (e) => onChangeForm(PERSONAL_INFO_KEYS.LAST_NAME, e.target.value),
            }}
            errorProps={{error: errors[PERSONAL_INFO_KEYS.LAST_NAME]}}
          />
        </RequiredContainer>
        
      </CustomContainer>
    </CustomCard>
  );
};

export default Personal;
