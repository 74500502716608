const GROUP_INFO_KEYS = {
    IMAGE: 'img',
    NAME: 'name',
    STREET_ADDRESS: 'streetAddress',
    STREET_NUMBER: 'streetNumber',
    ZIP_CODE: 'zipCode',
    COUNTRY_ADDRESS: 'countryAddress',
    STATE_ADDRESS: 'stateAddress',
    CITY_ADDRESS: 'cityAddress',
    TIME_ZONE: 'timezone',
};

export default GROUP_INFO_KEYS;