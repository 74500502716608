export const availableCountries = [{
  value: 'MEX',
  label: {
      en: 'Mexico',
      es: 'México'
  }
},{
  value: 'USA',
  label: {
      en: 'USA',
      es: 'EUA'
  }
}];