import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getJson } from 'resources/api';
import { processError } from 'state/utils';
import Types from './types';

function* fetchStartAsync() {
  try {
    const mx_states = yield getJson('/mx-states-2.json');
    const mx_cities = yield getJson('/mx-cities-2.json');

    const usa_states = yield getJson('/usa-states.json');
    const usa_cities = yield getJson('/usa-cities.json');
    
    const states_mx = mx_states.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    const states_usa = usa_states.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    yield put({
      type: Types.FETCH_SUCCESS,
      payload: {
        states: {'MEX': states_mx, 'USA': states_usa},
        cities: {'MEX': mx_cities, 'USA': usa_cities},
      },
    });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

export default function* sagas() {
  yield all([call(fetchStart)]);
}
