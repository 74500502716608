import { useState } from "react";
import { lookupViaCity, findFromCityStateProvince, findFromIsoCode } from 'city-timezones';

const useCountryOptions = () => {
    const [data, setData] = useState({
      country: undefined, 
      state: undefined,
      city: undefined,
      states: [], 
      cities: [],
      stateCities: [], 
      timezones: []
    });

    const setCountry = ( countryData, places ) => {
      const { country, state = undefined, city = undefined } = countryData;
      const newData = {
        ...data,
        timezones: getTimezones({
          countryLabel: country.value,
          state,
          city
        }),
        country,
        state,
        city,
        states: places.states[country.value],
        cities: places.cities[country.value],
      }

      setData(newData);
    }

    const setState = ( stateOption ) => {  
      const stateCities = data.cities.filter(({ state_id }) => state_id == stateOption.value);
      const timezones = getTimezones({state: stateOption});
      const newData = {
        ...data,
        state: stateOption, 
        stateCities,
        timezones
      }

      setData(newData);
    }

    const setCity = ( cityOption ) => {
      const timezones = getTimezones({city: cityOption});
      const newData = {
        ...data,
        city: cityOption,
        timezones
      }

      setData(newData);
    }

    const getTimezones = ({countryLabel = data.country.value, state = data.state, city = data.city}) => {
      if(!countryLabel) return[];

      let timeZones = [];

      if(!!city){        
        const cityName = city.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const cityTimeZones = lookupViaCity(cityName);
        timeZones = cityTimeZones.filter( timeZone => timeZone.iso3 == countryLabel );
      }
  
      if(!!state){
        const stateTimeZones = findFromCityStateProvince(state.label);
        timeZones = [
          ...timeZones,
          ...stateTimeZones.filter( timeZone => timeZone.iso3 == countryLabel )
        ]
      }
  
      const country_timezones = findFromIsoCode(countryLabel);
      timeZones = [
        ...timeZones,
        ...country_timezones.filter( timeZone => timeZone.iso3 == countryLabel )
      ]
  
      timeZones = timeZones.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.city === value.city && t.city_ascii === value.city_ascii
        ))
      )
      
      return timeZones.map( timeZone => ({
        value: timeZone.city_ascii,
        label: `${timeZone.timezone} (${timeZone.city_ascii}/${timeZone.province})`,
        timezone: timeZone.timezone
      }));
    }

    return { 
      countryOptions: data,
      setCountry,
      setState, 
      setCity
     };
}
export default useCountryOptions;