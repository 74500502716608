import Types from './types';
import { DEFAULT_NO_ERROR } from 'state/constants';

const DEFAULT_FETCHING_STATE = {state: false, config: {}};

const INITIAL_STATE = {
    countries: [],
    states: {'USA':[], 'MEX': []},
    cities: {'USA':[], 'MEX': []},
    fetching: {state: false, config: {}},
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START: return { ...state, fetching: {...DEFAULT_FETCHING_STATE, state: true} }
        case Types.FETCH_SUCCESS: return { ...state, states: action.payload.states, cities: action.payload.cities, fetching: {...DEFAULT_FETCHING_STATE}, error: { ...DEFAULT_NO_ERROR } }
        case Types.FETCH_ERROR: return { ...state, fetching: {...DEFAULT_FETCHING_STATE}, error: {timestamp: Date.now(), message: action.payload} }

        case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

        default: return state;
    }
}

export default reducer