import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { WRONG_DARK } from '@etiquette-ui/colors';

const Container = styled.div`
  position: relative;
`;

const Label = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 11px;
  color: ${WRONG_DARK};
  z-index: 0;
  top: -15px;
  left: 1px;
`;

const RequiredContainer = ({children, isRequired = true}) => {
  const {translate} = useI18n();
  return (
    <Container>
      {isRequired && (<Label>* {translate('Required')}</Label>)}
      {children}
    </Container>
  )
}
export default RequiredContainer;
