import React from 'react';
import styled from 'styled-components';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';

const CustomDropdown = styled(Dropdown)`

  .dropdown-module_inputContainer__25Hsy {
    flex-wrap: nowrap;
  }
  
  .selector__single-value{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;

    @media( min-width: 1100px){
      max-width: 250px;
    }

    @media( min-width: 1300px){
      max-width: 330px;
    }

    @media( min-width: 1460px){
      max-width: 350px;
    }
    
  }
`

const Select = (props) => {
  return (
    <CustomDropdown
      {...props}
    />
  )
}
export default Select;